import "./CreateEvent.css";
import { useNavigate, useParams } from "react-router-dom";
import { Suspense, useContext, useEffect, useState } from "react";
import CustomImage from "../CustomImage";
import {
  getEventPlaceholderindex,
  getPlaceholderImage,
  getPlaceholderImageUser,
  getProfilePlaceholderIndex,
} from "../../utils/images";
import TextImageButton from "../TextImageButton";
import SignupButton from "../SignupButton";
import ImageButton from "../ImageButton";
import DialogPopup from "../DialogPopup";
import SignupView from "../SignupView";
import UserContext from "../../context/UserContext";
import {
  auth,
  createEvent,
  getDocument,
  getNewDocId,
  getSignedUrlFromCloudflare,
  logoutUser,
  updateEvent,
} from "../../config/firebase";
import DefaultButton from "../DefaultButton";
import DefaultInput from "../DefaultInput";
import SwitchButton from "../SwitchButton";
import "react-datepicker/dist/react-datepicker.css";
import DateComponent from "../DateComponent";
import TextView from "../TextView";
import AddressInput from "../AddressInput";
import { GenreListView } from "../GenreListView";
import FileLoader from "../FileLoader";
import { createImageUrl, uploadImageToCloudflare } from "../../utils/uploads";
import SignupProfileView from "../SignupProfileView";
const arrowIcon = require("../../assets/icon_arrow.png");

export default function CreateEvent() {
  const navigate = useNavigate();
  const {
    user,
    currentEvent,
    setCurrentEvent,
    userProfileData,
    didFinishRegister,
    setDidFinishRegister,
  } = useContext(UserContext);
  const [openSignup, setOpenSignup] = useState(false);
  let { id } = useParams();
  const [data, setData] = useState(null);
  const [bannerIndex, setBannerIndex] = useState(null);
  const [didFilloutUsername, setDidFillOutUsername] = useState(false);
  const [showText, setShowText] = useState(false);

  const [eventName, setEventName] = useState("");
  const [location, setLocation] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isNow, setIsNow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [description, setDescription] = useState("");
  const [selectedGenres, setSelectedGenres] = useState([]);

  const [banner, setBanner] = useState(null);
  const [bannerSrc, setBannerSrc] = useState(null);

  const [eventNameError, setEventNameError] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [timeError, setTimeError] = useState(null);

  const [createEventLoading, setCreatingEventLoading] = useState(false);

  const [didStartSavingEvent, setDidStartSavingEvent] = useState(false);

  const [reload, setReload] = useState(false);

  const handleSetData = (data) => {
    const {
      name,
      bannerIndex,
      banner,
      description,
      startDate,
      endDate,
      isPrivate,
      location,
      genres,
    } = data;
    setData(data);
    setEventName(name);
    setBannerIndex(bannerIndex);
    setBannerSrc(banner);
    setDescription(description);
    setStartDate(new Date(startDate));
    setEndDate(new Date(endDate));
    setIsPrivate(isPrivate);
    setLocation(location);
    setSelectedGenres(genres);
  };

  const fetchEventData = async (id) => {
    if (id === currentEvent?.id) {
      handleSetData(currentEvent);
    } else {
      const doc = await getDocument(id);
      handleSetData(doc);
    }
  };

  useEffect(() => {
    if (!bannerIndex) {
      setBannerIndex(getEventPlaceholderindex());
    }
  }, []);

  useEffect(() => {
    if (banner) {
      const url = URL.createObjectURL(banner);
      setBannerSrc(url);
    }
  }, [banner]);

  useEffect(() => {
    if (id) {
      fetchEventData(id);
    }
  }, [id]);

  const handleLogout = async () => {
    if (!user) return;
    await logoutUser();
    navigate("/create-event");
    setReload(!reload);
  };

  const handleUpdateEvent = async () => {
    if (!user) {
      //
      console.log("user not logged in");

      //do logic
      return;
    }

    if (!eventName || eventName.length === 0) {
      setEventNameError("Please fill out the event name");
      return;
    }
    if (!location) {
      setLocationError("Please fill out the location name");
      return;
    }

    if (!location.place_id) {
      setLocationError("Invalid location - no place found");
      return;
    }

    if (!isNow && !startDate) {
      setTimeError("Please set a start date of your event");
    }
    setCreatingEventLoading(true);

    const now = new Date();
    const hoursInMs = 4 * 60 * 60 * 1000;

    const eventData = {
      ...data,
      name: eventName,
      name_small: eventName.toLowerCase(),
      location: location,
      isPrivate: isPrivate,
      description: description,
      startDate: isNow ? now : startDate,
      endDate: endDate || new Date(now.getTime() + hoursInMs),
      genres: selectedGenres,
    };

    try {
      if (banner) {
        const signedUrl = await getSignedUrlFromCloudflare(true);
        await uploadImageToCloudflare(banner, signedUrl);
        const bannerUrl = createImageUrl(signedUrl);
        eventData.banner = bannerUrl;
      }
      const res = await updateEvent(eventData, eventData.id);
      setCreatingEventLoading(false);
      setCurrentEvent(null);
      if (res.success) {
        navigate(`/events/${eventData.id}`, { reload: true });
      }
    } catch (error) {}
  };

  const handleCreateEvent = async () => {
    //CHECK IF WE ARE NOT LOGGED IN!

    if (!eventName || eventName.length === 0) {
      setEventNameError("Please fill out the event name");
      return;
    }
    if (!location) {
      setLocationError("Please fill out the location name");
      return;
    }

    if (!location.place_id) {
      setLocationError("Invalid location - no place found");
      return;
    }

    if (!isNow && !startDate) {
      setTimeError("Please set a start date of your event");
    }

    if (!user) {
      setOpenSignup(true);
      setDidStartSavingEvent(true);
      setCreatingEventLoading(false);
      return;
    }

    setCreatingEventLoading(true);
    const docId = getNewDocId("Events");
    const now = new Date();
    const hoursInMinutes = 8 * 60 * 60 * 1000;

    const data = {
      id: docId,
      name: eventName,
      name_small: eventName.toLowerCase(),
      location: location,
      isPrivate: isPrivate,
      description: description,
      bannerIndex: bannerIndex,
      userId: auth.currentUser.uid,
      isPopupEvent: false,
      startDate: isNow ? now : startDate,
      genres: selectedGenres,
    };

    if (isNow || !endDate) {
      data.endDate = new Date(now.getTime() + hoursInMinutes);
    } else {
      data.endDate = endDate;
    }

    try {
      if (banner) {
        const signedUrl = await getSignedUrlFromCloudflare(true);
        await uploadImageToCloudflare(banner, signedUrl);
        const bannerUrl = createImageUrl(signedUrl);
        data.banner = bannerUrl;
      }
      const res = await createEvent(data, docId);
      setCreatingEventLoading(false);
      if (res.success) {
        navigate(`/events/${docId}`);
      }
    } catch (error) {
      setCreatingEventLoading(false);
    }
  };

  useEffect(() => {
    if (didStartSavingEvent && didFinishRegister) {
      setDidFinishRegister(false);
      setDidStartSavingEvent(false);
      handleCreateEvent();
    }
  }, [didStartSavingEvent, didFinishRegister]);

  return (
    <div className="event-container">
      <div className="event-image logo-block">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src={require("../../assets/trppn_logo.png")}
            style={{ height: 25 }}
          />
          <ImageButton />
        </div>
      </div>
      <div className="event-image">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "inline-block",
              position: "relative",
            }}
          >
            <Suspense>
              <CustomImage
                style={{
                  width: 393,
                  borderRadius: 10,
                  marginBottom: 8,
                  display: "block",
                  opacity: !bannerSrc ? 0.4 : 1,
                }}
                url={bannerSrc || getPlaceholderImage(bannerIndex)}
              />
            </Suspense>
            <div
              style={{
                position: "absolute",
                top: "40%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {/* <SignupButton
                title="+Add Flyer"
                style={{
                  background: "#E7FF85",
                  width: 130,
                }}
              /> */}
              <FileLoader
                title={!bannerSrc ? "+Add Flyer" : "Change Flyer"}
                setFile={setBanner}
                style={{
                  background: "#E7FF85",
                  width: 130,
                  opacity: bannerSrc ? 0.75 : 1,
                }}
              />
              {!bannerSrc && (
                <p
                  className="apple-google-text"
                  style={{
                    margin: 0,
                    marginTop: 10,
                    color: "#92949C",
                    fontSize: 15,
                  }}
                >
                  Video or image{<br />}
                  Format 9:16 recommended
                </p>
              )}
            </div>
          </div>

          <DefaultButton
            title={!id ? "Create event" : "Update event"}
            onClick={!id ? handleCreateEvent : handleUpdateEvent}
            style={{ width: "100%" }}
            loading={createEventLoading}
          />
        </div>
      </div>
      <div className="event-content">
        <div style={{ width: "92%", marginTop: 0 }}>
          <DefaultInput
            style={{ width: "100%", padding: 15 }}
            placeholder={"Event name"}
            value={eventName}
            setValue={setEventName}
            onFocus={() => {
              setEventNameError(null);
              setLocationError(null);
            }}
          />
          {!!eventNameError && (
            <span className="apple-google-text" style={{ color: "red" }}>
              {eventNameError}
            </span>
          )}
        </div>
        <div style={{ width: "92%", marginTop: 8 }}>
          <AddressInput
            init={location?.description}
            setLocation={setLocation}
            onFocus={() => {
              setEventNameError(null);
              setLocationError(null);
            }}
          />
          {!!locationError && (
            <span className="apple-google-text" style={{ color: "red" }}>
              {locationError}
            </span>
          )}
        </div>
        <div style={{ marginTop: 8 }}>
          <SwitchButton
            text={"Private Event"}
            value={isPrivate}
            setValue={setIsPrivate}
          />
        </div>
        <div style={{ marginTop: 8 }}>
          <SwitchButton text={"Now"} value={isNow} setValue={setIsNow} />
          {!isNow && (
            <>
              <DateComponent
                text="Start Date"
                date={startDate}
                setDate={(d) => setStartDate(d)}
                maxDate={endDate}
              />
              <DateComponent
                text="End Date"
                date={endDate}
                setDate={(d) => setEndDate(d)}
                minDate={startDate}
              />
              {timeError && (
                <span className="apple-google-text">{timeError}</span>
              )}
            </>
          )}
        </div>

        <div style={{ marginTop: 8 }}>
          <TextView
            value={description}
            setValue={setDescription}
            placeholder="Description"
          />
        </div>

        <div
          style={{
            marginTop: 8,
          }}
        >
          <span
            className="apple-google-text"
            style={{
              color: "#999999",
              fontSize: 15,
              margin: 0,
            }}
          >
            SELECT MUSIC STYLES
          </span>
          <div style={{ height: 10 }} />
          <GenreListView
            selectedGenres={selectedGenres}
            setSelectedGenres={setSelectedGenres}
          />
        </div>

        <div style={{ width: "100%", marginTop: 300 }}>
          <TextImageButton
            text="Loggout"
            iconName={arrowIcon}
            onClick={handleLogout}
          />
        </div>
      </div>

      <>
        <SignupProfileView user={user} open={openSignup} />
      </>

      {/* {!user ? (
        <div
          className="login-area"
          style={{ position: "absolute", right: 15, top: 70 }}
        >
          <SignupButton
            style={{ background: "#E7FF85", marginBottom: 10 }}
            onClick={() => setOpenSignup(true)}
          />
          <SignupButton
            textColor="#E7FF85"
            style={{
              background: "transparent",
              borderWidth: 1,
              borderColor: "#E7FF85",
            }}
            onClick={() => setOpenSignup(true)}
            title="Login"
          />
        </div>
      ) : (
        <div
          className="login-area profile-area"
          style={{ position: "absolute", right: 15, top: 70 }}
          onClick={() => console.log("clocked ")}
        >
          <img
            alt=""
            style={{ width: 40, height: 40, borderRadius: 20 }}
            src={
              !userProfileData
                ? null
                : userProfileData?.thumbnailUrl ||
                  getPlaceholderImageUser(userProfileData?.phIndex)
            }
          />
          <span className="apple-google-text" style={{ fontSize: 15 }}>
            {userProfileData?.username || ""}
          </span>
        </div>
      )} */}
    </div>
  );
}
