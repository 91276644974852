import { useState } from "react";
import { getShareLink } from "../../utils/redirect";
import "./ShareView.css";

export default function ShareView({ close, shareId }) {
  const [copied, setCopied] = useState(false);
  const handleClick = async () => {
    setCopied(true);
    const link = getShareLink(shareId);
    await navigator.clipboard.writeText(link);
    setTimeout(() => {
      close();
    }, 1000);
  };
  return (
    <>
      <div className="upper-container">
        <p className="header-text"> Copy the link and invite your guests</p>
      </div>
      <div className="bottom-container">
        <button
          className="button-style"
          onClick={handleClick}
          style={{ width: 160 }}
        >
          {!copied ? (
            <>
              <img
                src={require("../../assets/icon_copy.png")}
                style={{ height: 20, marginLeft: 10 }}
              />
              <div style={{ marginRight: 10 }}>
                <p className="header-text" style={{ color: "#3788e6" }}>
                  Copy Link
                </p>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: 150,
                justifyContent: "center",
              }}
            >
              <p className="header-text" style={{ color: "#3788e6" }}>
                Link copied!
              </p>
            </div>
          )}
        </button>
      </div>
    </>
  );
}
