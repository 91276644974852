import "./EmailView.css";
import { useEffect, useState } from "react";
import {
  auth,
  checkUsername,
  isEmailVerified,
  loginUser,
  registerUser,
  reloadAuth,
  resendVerificationEmail,
} from "../../config/firebase";
import { firebasAuthErrors } from "../../utils/error";
import { getProfilePlaceholderIndex } from "../../utils/images";
import DefaultButton from "../DefaultButton";
import DefaultInput from "../DefaultInput";
import ReactLoadingComponent from "../../ReactLoadingComponent";
import { useContext } from "react";
import UserContext from "../../context/UserContext";

export default function EmailView({ closePopup, setDidFillOutUsername }) {
  const { setDidFinishRegister, handleGrabAndSetProfileData } =
    useContext(UserContext);

  const [isLogin, setIsLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [verificationView, setVerificationView] = useState(false);
  const [didResendEmail, setDidResendEmail] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [verificationError, setVerificationError] = useState(null);

  const [continueLoading, setContinueLoading] = useState(false);

  useEffect(() => {
    setDidFillOutUsername(true);
  }, []);

  const handleLoginRegister = async () => {
    setError("");
    if (email.length === 0 || password.length === 0) {
      setError("Email and password must be filled out");
      return;
    }
    if (!isLogin && username.length === 0) {
      setError("Please fill out the username");
      return;
    }

    if (!isLogin && username.length > 27) {
      setError("Your username is too long friend");
      return;
    }

    if (!isLogin && username.length < 2) {
      setError("Username is too short");
      return;
    }
    if (!isLogin && password2 !== password) {
      setError("Passwords do not match");
      return;
    }

    setLoading(true);

    if (!isLogin) {
      try {
        const usernameExists = await checkUsername(username);
        console.log("is valid user ");
        if (usernameExists) {
          setError("Username is already taken. Please choose a different one");
          return;
        }
        const res = await registerUser(
          email,
          password,
          getProfilePlaceholderIndex(),
          username
        );
        if (res.success) {
          //What to do after reggi??
          //Here we should have a screen for Email Verification
          //Show Message: Verify Email and Click "Continue"
          setVerificationView(true);
          handleGrabAndSetProfileData(auth.currentUser.uid);
        } else {
          setError("Uuups, something went wrong. Please try again!");
          console.log("the error is ");
        }
        setLoading(false);
      } catch (error) {
        setError(firebasAuthErrors(error.code));
        setLoading(false);
      }
    } else {
      //LOGIN:
      try {
        const res = await loginUser(email, password);
        if (res.success) {
          setLoading(false);
          setDidFinishRegister(true);
          closePopup();
        }
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }
  };

  const handleCheckEmailVerif = async () => {
    setVerificationError(null);
    console.log("auth is ", auth.currentUser);
    setContinueLoading(true);
    const isVerified = await isEmailVerified();
    console.log("te reloaded user is ", isVerified);
    if (isVerified) {
      setDidFinishRegister(true);
      closePopup();
    } else {
      setVerificationError("Please verify your email before continuing.");
    }
    setContinueLoading(false);
  };

  const handleResend = async () => {
    setResendLoading(true);
    const res = await resendVerificationEmail();
    console.log("the res: ", res);
    setResendLoading(false);
    setDidResendEmail(true);
  };

  return (
    <>
      {!verificationView && (
        <>
          {!isLogin && (
            <div style={{ marginBottom: 8 }}>
              <p
                className="apple-google-text"
                style={{ marginLeft: 0, marginBottom: 5 }}
              >
                Username
              </p>
              <DefaultInput
                placeholder={"Username"}
                setValue={setUsername}
                value={username}
                onFocus={() => setError("")}
              />
            </div>
          )}
          <div style={{ marginBottom: 8 }}>
            <p
              className="apple-google-text"
              style={{ marginLeft: 0, marginBottom: 5 }}
            >
              Email Address
            </p>
            <DefaultInput
              placeholder={"Email"}
              setValue={setEmail}
              value={email}
              onFocus={() => setError("")}
            />
          </div>
          <div style={{ marginBottom: !isLogin ? 8 : 64 }}>
            <p
              className="apple-google-text"
              style={{ marginLeft: 0, marginBottom: 5 }}
            >
              Password
            </p>
            <DefaultInput
              placeholder={"Password"}
              passwordMode={true}
              setValue={setPassword}
              value={password}
              onFocus={() => setError("")}
            />
            {isLogin && error.length > 0 && (
              <div style={{ marginTop: 5 }}>
                <span
                  className="apple-google-text"
                  style={{
                    marginLeft: 0,
                    fontSize: 13,
                    color: "#E7FF85",
                  }}
                >
                  {error}
                </span>
              </div>
            )}
          </div>
          {!isLogin && (
            <div style={{ marginBottom: 50 }}>
              <p
                className="apple-google-text"
                style={{ marginLeft: 0, marginBottom: 5 }}
              >
                Repeat Password
              </p>
              <DefaultInput
                placeholder={"Password"}
                passwordMode={true}
                setValue={setPassword2}
                value={password2}
                onFocus={() => setError("")}
              />
              {!isLogin && error.length > 0 && (
                <div style={{ marginTop: 5 }}>
                  <span
                    className="apple-google-text"
                    style={{
                      marginLeft: 0,
                      fontSize: 13,
                      color: "#E7FF85",
                    }}
                  >
                    {error}
                  </span>
                </div>
              )}
            </div>
          )}
          <div
            style={{
              marginBottom: 10,
              textAlign: "center",
            }}
          >
            <p
              className="apple-google-text"
              style={{
                marginLeft: 0,
                marginBottom: 0,
                fontSize: 13,
                opacity: 0.7,
              }}
            >
              {!isLogin
                ? "Already have an account?"
                : "Don't have an account yet?"}
            </p>
            <button
              style={{
                background: "transparent",
                border: "none",
                outline: "none",
                padding: 8,
              }}
              onClick={() => {
                setError("");
                setIsLogin(!isLogin);
              }}
            >
              <span
                className="apple-google-text"
                style={{ color: "#E7FF85", fontSize: 15 }}
              >
                {!isLogin ? "LOGIN" : "REGISTER"}
              </span>
            </button>
          </div>
          <div
            style={{
              marginBottom: 30,
              textAlign: "center",
            }}
          >
            <DefaultButton
              title={!isLogin ? "Register" : "Login"}
              onClick={handleLoginRegister}
              loading={loading}
            />
          </div>
        </>
      )}

      {verificationView && (
        <div
          style={{
            marginBottom: 30,
            textAlign: "center",
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <p className="apple-google-text" style={{ width: "70%", margin: 0 }}>
            Verification email was send.{<br />}Please click the link in the
            email and click the Continue button here.
          </p>
          <p
            className="apple-google-text"
            style={{ width: "70%", margin: 0, marginTop: 5, marginBottom: 0 }}
          >
            Note: check the spam folder in case you haven't received the email.
          </p>

          <button
            onClick={handleResend}
            className="plain-button"
            style={{ marginBottom: 30, color: "#E7FF85" }}
          >
            {didResendEmail ? (
              <span className="apple-google-text" style={{ color: "#E7FF85" }}>
                Verification Email resent successfully
              </span>
            ) : (
              <>
                {resendLoading ? (
                  <ReactLoadingComponent />
                ) : (
                  <span
                    className="apple-google-text"
                    style={{ color: "#E7FF85", textDecoration: "underline" }}
                  >
                    Resend
                  </span>
                )}
              </>
            )}
          </button>

          <DefaultButton
            title={"Continue"}
            onClick={handleCheckEmailVerif}
            loading={continueLoading}
          />
          {!!verificationError && (
            <span className="apple-google-text" style={{ color: "#E7FF85" }}>
              {verificationError}
            </span>
          )}
        </div>
      )}
    </>
  );
}
