import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  OAuthProvider,
  sendEmailVerification,
  signInWithCredential,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import getFirebaseConfig from "./firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  initializeFirestore,
  setDoc,
  serverTimestamp,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { unstable_ClassNameGenerator } from "@mui/material";
const conf = getFirebaseConfig();
const app = initializeApp(conf);
const auth = getAuth(app);
const functions = getFunctions(app);
const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,
  useFetchStreams: false,
});

const getDocument = async (docId) => {
  const fetchEvent = httpsCallable(functions, "fetchEventUnauth");
  try {
    const res = await fetchEvent({
      docId: docId,
    });
    return res.data;
  } catch (error) {
    console.log("the error ", error);
  }
};

const getUserInfoAuthenticated = async (uid) => {
  const userRef = doc(db, `Users/${uid}`);
  try {
    const res = await getDoc(userRef);
    if (res.exists) {
      const userData = res.data();
      return userData;
    }
    return null;
  } catch (error) {
    console.log("the error ", error);
  }
};

const getUserInfo = async (uid) => {
  const fetchUser = httpsCallable(functions, "fetchUserUnauth");
  try {
    const res = await fetchUser({
      uid: uid,
    });
    return res.data;
  } catch (error) {
    console.log("the error ", error);
  }
};

const getUsersInfos = async (uids) => {
  const fetchUsers = httpsCallable(functions, "fetchUsersUnauth");
  try {
    const res = await fetchUsers({
      uids: uids,
    });
    return res.data;
  } catch (error) {
    console.log("the error ", error);
  }
};

const printMessage = async (message) => {
  const print = httpsCallable(functions, "printConsole");
  try {
    const res = await print({
      message: message,
    });
    return res.data;
  } catch (error) {
    console.log("the error ", error);
  }
};

const updateShareNodeWebview = async (
  shareId,
  comeFromIg,
  type,
  deviceType
) => {
  const updateFunc = httpsCallable(functions, "updateShareNodeWeb");
  try {
    const res = await updateFunc({
      comeFromIg: comeFromIg,
      type: type,
      shareId: shareId,
      deviceType: deviceType,
    });
    return res.data;
  } catch (error) {
    console.log("the error ", error);
  }
};

const getOAuthProvider = (name) => {
  return new OAuthProvider(name);
};

const registerWithAuthToken = async (credentials, ind) => {
  try {
    const { user } = await signInWithCredential(auth, credentials);
    console.log("the user is ", user);
    const snap = await getDoc(doc(db, "Users", user.uid));

    const comeFromLogin = snap.exists();
    console.log("come from login ", comeFromLogin);

    if (!comeFromLogin) {
      console.log("in here");
      await setUserData(auth.currentUser, ind || 0);
    }
    return { success: true, comeFromLogin: comeFromLogin };
  } catch (error) {
    //TODO: Handle Firebase error here and pass on
    console.log("error on Firebase side of apple signup process", error);
    throw error;
  }
};

const registerUserDirectly = async (user, ind) => {
  try {
    console.log("the user is ", user.uid);
    const snap = await getDoc(doc(db, "Users", user.uid));

    const comeFromLogin = snap.exists();
    console.log("come from login ", comeFromLogin);

    if (!comeFromLogin) {
      console.log("in here");
      await setUserData(auth.currentUser, ind || 0);
    }
    return { success: true, comeFromLogin: comeFromLogin };
  } catch (error) {
    //TODO: Handle Firebase error here and pass on
    console.log("error on Firebase side of apple signup process", error);
    throw error;
  }
};

const setUserData = async (user, index) => {
  const docRef = doc(db, `Users/${user.uid}`);
  try {
    await setDoc(docRef, {
      email: user.email,
      myEvents: [],
      attendedEvents: [],
      type: 0,
      phIndex: index,
      created_at: serverTimestamp(),
      wr: true,
    });
  } catch (error) {
    throw error;
  }
};

const checkUsername = async (username) => {
  try {
    const q = query(collection(db, "Users"), where("username", "==", username));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.length > 0;
  } catch (error) {
    console.log("Oh oh, error in username check; ", error);
    return { success: false };
  }
};

const updateUser = async (username) => {
  const uid = auth.currentUser?.uid;
  const userRef = doc(db, `Users/${uid}`);

  try {
    const res = await updateDoc(userRef, {
      username: username,
      un_small: username.toLowerCase(),
    });
    return { success: true };
  } catch (error) {
    console.log("Oh oh, error in username check; ", error);
    return { success: false };
  }
};

const logoutUser = async () => {
  try {
    await auth.signOut();
  } catch (error) {
    throw error;
  }
};

const registerUser = async (email, password, ind, username) => {
  try {
    const userCreds = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    await setUserData(auth.currentUser, ind);
    await updateUser(username);
    await sendEmailVerification(userCreds.user);
    return { success: true };
  } catch (error) {
    throw error;
  }
};

const loginUser = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    console.log("the error is ", userCredential);
    return { success: true };
  } catch (error) {
    throw error;
  }
};

const getAddressAsync = async (searchInput) => {
  const callable = httpsCallable(functions, "getAddress");
  try {
    const result = await callable({ text: searchInput });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getList = async (listName) => {
  const collectionRef = collection(db, "Lists");
  const docRef = doc(collectionRef, listName);
  try {
    const snapshot = await getDoc(docRef);
    const data = snapshot.data();
    if (listName === "Genres") {
      const genreList = data.list.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      return genreList;
    }
    return data.list;
  } catch (error) {
    console.log("Error grabing list: ", listName, error);
    return [];
  }
};

const getSignedUrlFromCloudflare = async (isImageUpload) => {
  const signedUrlFunc = httpsCallable(functions, "getSignedUrl");
  try {
    const response = await signedUrlFunc({ imageUpload: !!isImageUpload });
    return response.data.signedUrl;
  } catch (error) {
    console.error("Error getting signed URL: ", error);
    throw error;
  }
};

const getNewDocId = (collectionName) => {
  return doc(collection(db, collectionName)).id;
};

const createEvent = async (eventData, id) => {
  const callable = httpsCallable(functions, "createEvent");
  try {
    const result = await callable({
      eventData: eventData,
      docId: id,
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const updateEvent = async (eventData, id) => {
  const callable = httpsCallable(functions, "updateEvent");
  try {
    const result = await callable({
      eventData: eventData,
      docId: id,
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const isEmailVerified = async () => {
  const callable = httpsCallable(functions, "checkEmailVerification");
  try {
    const result = await callable();
    return result.data.emailVerified;
  } catch (error) {
    console.log("error checkingn email verification status ", error);
    return { success: false, error: error };
  }
  // const user = await
  // console.log("in reload auth", user);
};

const resendVerificationEmail = async () => {
  if (!auth.currentUser) return;
  try {
    const user = auth.currentUser;
    if (!user?.emailVerified) {
      const res = await sendEmailVerification(user);
      console.log("the res is ", res);
      return { success: true };
    } else {
      return { success: false, msg: "verified" };
    }
  } catch (error) {
    console.log("error resending verific email: ", error);
    return { success: false, error: error };
  }
};

export {
  auth,
  createEvent,
  getAddressAsync,
  getDocument,
  getList,
  getNewDocId,
  getSignedUrlFromCloudflare,
  getUserInfo,
  getUserInfoAuthenticated,
  isEmailVerified,
  loginUser,
  printMessage,
  getUsersInfos,
  getOAuthProvider,
  registerWithAuthToken,
  checkUsername,
  logoutUser,
  registerUser,
  registerUserDirectly,
  resendVerificationEmail,
  updateEvent,
  updateShareNodeWebview,
  updateUser,
};
