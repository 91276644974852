import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Event from "./components/Event";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import "./App.css";
import CreateEvent from "./components/CreateEvent";
import { auth } from "./config/firebase";
import UserContext, { UserProvider } from "./context/UserContext";

function App() {
  const { setUser } = useContext(UserContext);

  const checkUser = () => {
    const unsubscr = auth.onAuthStateChanged((user) => {
      if (!!user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return unsubscr;
  };

  useEffect(() => {
    checkUser();
  }, []);

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/events/:id" element={<Event />} />
        <Route path="/create-event" element={<CreateEvent />} />
        <Route path="/create-event/:id" element={<CreateEvent />} />
      </Routes>
    </Router>
  );
}

export default App;
