import React, { Suspense, useContext, useEffect, useState } from "react";
import TextImageButton from "../TextImageButton";
import { useNavigate, useParams } from "react-router-dom";

import "./Event.css";
import {
  getDocument,
  getUsersInfos,
  printMessage,
  updateShareNodeWebview,
} from "../../config/firebase";
import AttendButton from "../AttendButton";
import {
  getPlaceholderImage,
  getPlaceholderImageUser,
} from "../../utils/images";
import { GenreListView } from "../GenreListView";
import DialogView from "../DialogView";
import { getDateStringRep, getHourStringRep } from "../../utils/date";
import {
  appRedirect,
  extractIdFromRoute,
  getDeviceType,
  isInstagramInAppBrowser,
  isWeb,
  shareLink,
} from "../../utils/redirect";
import LoadingIndicator from "../LoadingIndicator";
import CustomImage from "../CustomImage";
import DialogPopup from "../DialogPopup";
import QRView from "../QRView";
import ShareView from "../ShareView";
import UserContext from "../../context/UserContext";
const arrowIcon = require("../../assets/icon_arrow.png");

const Event = () => {
  const navigate = useNavigate();

  const { user, setCurrentEvent, currentEvent } = useContext(UserContext);
  const [data, setData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [shareId, setShareId] = useState(null);

  const [isOpen, setOpen] = useState(false);
  const [showQRPopup, setShowQRPopup] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);

  const params = useParams();

  const fetchEvent = async () => {
    const routeObject = extractIdFromRoute(params.id);

    if (!!currentEvent && routeObject.eventId === currentEvent.id) {
      setData(currentEvent);
      setShareId(routeObject.shareId);
      return;
    }
    const data = await getDocument(routeObject.eventId);
    setCurrentEvent(data);
    setData(data);
    setShareId(routeObject.shareId);
  };

  const fetchCommentsContents = async () => {
    if (!data.comments || data.comments.length === 0) {
      setUserData([]);
      return;
    }
    const uids = [];
    data.comments.forEach((comment, ind) => {
      if (ind > 30) return;
      const { uid } = comment;
      if (!!uid && !uids.includes(uid)) {
        uids.push(uid);
      }
    });
    if (uids.length === 0) {
      setUserData([]);
      return;
    }
    const users = await getUsersInfos(uids);
    setUserData(users);
  };

  //TODO: make order -> first check if valid params
  useEffect(() => {
    const userAgent = navigator.userAgent;
    printMessage(userAgent.toString());
    fetchEvent();
  }, []);

  useEffect(() => {
    if (!!data) {
      fetchCommentsContents();
    }
  }, [data]);

  const handleShare = async () => {
    if (isWeb(navigator.userAgent)) {
      setShowSharePopup(true);
    } else {
      await shareLink(shareId, () => setShowSharePopup(true));
    }
  };

  const handleRedirects = async (appOnlyRedirect, type) => {
    const device = getDeviceType(navigator.userAgent);
    updateShareNodeWebview(shareId, isInstagramInAppBrowser(), type, device);
    if (isWeb(navigator.userAgent)) {
      setShowQRPopup(true);
    } else {
      appRedirect(shareId, appOnlyRedirect);
    }
  };

  const isEventAdmin = () => {
    if (!data) return false;
    return data.userId === user?.uid;
  };

  return (
    <Suspense fallback={<LoadingIndicator />}>
      {!data ? (
        <LoadingIndicator />
      ) : (
        <div className="event-container">
          <div className="event-image">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <CustomImage
                style={{ width: 393, borderRadius: 10, marginBottom: 8 }}
                url={data.banner || getPlaceholderImage(data?.bannerIndex)}
              />
              {/* // <img
              //   src={data.banner || getPlaceholderImage(data?.bannerIndex)}
              //   alt="Event"
              //   style={{ width: 393, borderRadius: 10, marginBottom: 8 }}
              // /> */}
              <AttendButton
                text="Attend"
                iconName={arrowIcon}
                onClick={() => handleRedirects(null, "ab")}
              />
            </div>
          </div>
          <div className="event-content">
            <span className="event-name">{data?.name}</span>
            <div style={{ width: "90%", marginTop: 15 }}>
              <span className="text">
                @{""}
                <span style={{ textDecorationLine: "underline" }}>
                  {data?.location?.description}
                </span>
              </span>
            </div>
            <div style={{ width: "90%", marginTop: 15 }}>
              <span className="text">
                {getDateStringRep(data.startDate)}{" "}
                {getHourStringRep(data.startDate)} -{" "}
                {getDateStringRep(data.endDate)}{" "}
                {getHourStringRep(data.endDate)}
                {", "}
                {new Date(data.startDate).getFullYear()}
              </span>
            </div>
            <div style={{ width: "90%", marginTop: 15 }}>
              <GenreListView
                selectedGenres={data?.genres || []}
                showSelectedOnly={true}
              />
            </div>
            <div style={{ width: "90%", marginTop: 15 }}>
              <span className="text">{data.description}</span>
            </div>
            <div
              style={{
                flex: 1,
                marginTop: 15,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                display: "flex",
              }}
            >
              <span
                className="text"
                style={{
                  color: "white",
                  fontWeight: 400,
                  fontSize: 12,
                  letterSpacing: 1,
                }}
              >
                EVENT HOSTED BY
              </span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <span
                  className="text"
                  style={{ fontSize: 17, fontWeight: 400, color: "white" }}
                >
                  {data.user?.username || ""}
                </span>
                <img
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 15,
                    marginLeft: 8,
                  }}
                  src={
                    data.user?.thumbnailUrl ||
                    getPlaceholderImageUser(data.user?.phIndex)
                  }
                  alt=""
                />
              </div>
            </div>
            {isEventAdmin() && (
              <div style={{ width: "100%", marginTop: 15 }}>
                <TextImageButton
                  text={"Edit Event"}
                  iconName={require("../../assets/icon_back_gray.png")}
                  onClick={() => navigate(`/create-event/${data?.id}`)}
                />
              </div>
            )}

            <div style={{ width: "100%", marginTop: isEventAdmin() ? 5 : 15 }}>
              <TextImageButton
                disabled={data.comments.length === 0}
                text={"Comments (" + data.comments.length + ")"}
                iconName={require("../../assets/icon_back_gray.png")}
                onClick={() => {
                  setOpen(true);
                }}
              />
            </div>
            <div style={{ width: "100%", marginTop: 5 }}>
              <TextImageButton
                text="Share"
                iconName={require("../../assets/icon_share.png")}
                onClick={handleShare}
              />
            </div>
            {/* <div style={{ width: "100%", marginTop: 5 }}>
              <TextImageButton
                text="Reels"
                iconName={arrowIcon}
                onClick={() => appRedirect(shareId)}
              />
            </div> */}
            <div style={{ width: "100%", marginTop: 5 }}>
              <TextImageButton
                text="Open in app"
                iconName={arrowIcon}
                onClick={() => handleRedirects(null, "oa")}
              />
            </div>
            <div style={{ width: "100%", marginTop: 25 }}>
              <TextImageButton
                text="Discover more events"
                className={"button-style2"}
                iconName={arrowIcon}
                onClick={() => handleRedirects(true, "de")}
              />
            </div>
            <div style={{ width: "100%", marginTop: 5 }}>
              <TextImageButton
                text="Host an event"
                className={"button-style2"}
                iconName={arrowIcon}
                onClick={() => navigate("/create-event")}
              />
            </div>
          </div>
        </div>
      )}
      {
        <DialogView
          open={isOpen}
          handleClose={() => setOpen(false)}
          data={data?.comments || []}
          userData={userData}
          onClick={() => handleRedirects(null, "cb")}
        />
      }
      {
        <DialogPopup
          open={showQRPopup}
          handleClose={() => setShowQRPopup(false)}
        >
          <QRView />
        </DialogPopup>
      }
      {
        <DialogPopup
          open={showSharePopup}
          handleClose={() => setShowSharePopup(false)}
          title={"Share Event"}
        >
          <ShareView close={() => setShowSharePopup(false)} shareId={shareId} />
        </DialogPopup>
      }
    </Suspense>
  );
};

export default Event;
