import { APPSTORE_URLS } from "../../constants";
import "./QRView.css";
const qrIos = require("../../assets/qr_ios.png");
const qrAndroid = require("../../assets/qr_android.png");

export default function QRView() {
  const qrSize = 200;
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 35 }}>
        <p className="header-text">
          Please open the app to access these features
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div
          className="container"
          style={{ alignItems: "flex-end", marginRight: 0 }}
        >
          <img
            src={qrIos}
            style={{
              width: qrSize,
              height: qrSize,
              borderRadius: 5,
              marginBottom: 25,
            }}
          />
          <a href={APPSTORE_URLS.ios} target="_blank" rel="noopener noreferrer">
            <img
              src={require("../../assets/button_appstore_w.png")}
              style={{ width: qrSize }}
              alt=""
            />
          </a>
        </div>
        <div
          className="container"
          style={{ alignItems: "flex-start", marginLeft: 0 }}
        >
          <img
            src={qrAndroid}
            style={{
              width: qrSize,
              height: qrSize,
              borderRadius: 5,
              marginBottom: 25,
            }}
          />
          <a
            href={APPSTORE_URLS.android}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require("../../assets/button_playstore_w.png")}
              style={{ width: qrSize }}
              alt=""
            />
          </a>
        </div>
      </div>
    </>
  );
}
