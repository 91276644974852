import { ColorRing } from "react-loader-spinner";

export default function ReactLoadingComponent({
  size = "25",
  rad = "9",
  color = "#001E8A",
}) {
  return (
    <ColorRing
      height={size}
      width={size}
      radius={rad}
      colors={[color, color, color, color, color, color]}
    />
  );
}
