import React, { useRef } from "react";

function FileLoader({ title, setFile, ...props }) {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Programmatically click the hidden file input element
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setFile(file);
    // Handle the file, for example, read its content or upload it
    console.log(file);
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }} // Hide the file input
      />
      <button
        className={`signup-button`}
        onClick={handleButtonClick}
        style={props.style}
      >
        <p className="signup-button-text" style={{ color: "black" }}>
          {title}
        </p>
      </button>
    </div>
  );
}

export default FileLoader;
