import { getTimeAgo } from "../../utils/date";
import { getPlaceholderImageUser } from "../../utils/images";
import CustomImage from "../CustomImage";

export const CommentsListItem = ({ item, userInfo }) => {
  const img =
    userInfo?.thumbnailUrl || getPlaceholderImageUser(userInfo.phIndex);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginBottom: 15,
      }}
    >
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >
        {!userInfo.thumbnailUrl ? (
          <img
            src={img}
            style={{ width: 42, height: 42, borderRadius: 21 }}
            alt=""
          />
        ) : (
          <CustomImage
            style={{ width: 42, height: 42, borderRadius: 21 }}
            url={userInfo.thumbnailUrl}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: 300,
          maxWidth: 300,
        }}
      >
        <div>
          <span
            className="text"
            style={{
              letterSpacing: 1,
              fontSize: 12,
              color: "white",
              marginBottom: 4,
            }}
          >
            {userInfo?.username?.toUpperCase() || ""}
          </span>
          <span
            className="text"
            style={{
              fontSize: 13,
              color: "#999999",
              marginLeft: 7,
            }}
          >
            {getTimeAgo(item.created_at, true)}
          </span>
        </div>

        <span
          className="text"
          style={{
            color: "#999999",
            fontSize: 15,
            letterSpacing: 0,
            marginTop: 4,
          }}
        >
          {item.message}
        </span>
      </div>
    </div>
  );
};
