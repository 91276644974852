import "./SignupButton.css";

export default function SignupButton({
  onClick,
  title = "Sign up",
  textColor = "black",
  ...props
}) {
  return (
    <button className={`signup-button`} style={props.style} onClick={onClick}>
      <p className="signup-button-text" style={{ color: textColor }}>
        {title}
      </p>
    </button>
  );
}
