import { useContext } from "react";
import UserContext from "../../context/UserContext";
import "./GenreListView.css";
export const GenreListView = ({
  selectedGenres,
  setSelectedGenres,
  showSelectedOnly,
}) => {
  const { allGenres } = useContext(UserContext);

  const renderSelectedGenres = () => {
    const res = [];
    const genreBlocks = [];
    selectedGenres.forEach((el, i) => {
      genreBlocks.push(
        <div key={i} className="genre-block">
          {el.name.toUpperCase()}
        </div>
      );
    });
    res.push(
      <div className="genre-container" key={"genre-block"}>
        {genreBlocks}
      </div>
    );

    return res;
  };

  const renderGenresList = () => {
    const res = [];
    const genreBlocks = [];

    allGenres.forEach((el, i) => {
      const isActive = handleCheckActiveGenre(el);
      genreBlocks.push(
        <button
          className="genre-block"
          onClick={() => handleAddRemoveElement(el)}
          key={"genre-" + el.id + el.name}
          style={{
            backgroundColor: "transparent",
            borderColor: isActive ? "#E7FF85" : "gray",
          }}
        >
          <span
            style={{
              color: isActive ? "#E7FF85" : "gray",
            }}
          >
            {el.name.toUpperCase()}
          </span>
        </button>
      );
    });

    res.push(
      <div key={"genre-block"} className="genre-container">
        {genreBlocks}
      </div>
    );
    return res;
  };

  const handleAddRemoveElement = (genre) => {
    console.log("in remove dunc", genre);
    console.log("the selected genre are ", selectedGenres);

    const newSelectedGenres = [...selectedGenres];
    const isActive = handleCheckActiveGenre(genre);

    if (isActive) {
      const filteredList = newSelectedGenres.filter(
        (item) => item.id !== genre.id
      );

      setSelectedGenres(filteredList);
    } else {
      newSelectedGenres.push(genre);
      setSelectedGenres(newSelectedGenres);
    }
  };

  const handleCheckActiveGenre = (genre) => {
    return selectedGenres.some((item) => item.id === genre.id);
  };

  return (
    <div>{showSelectedOnly ? renderSelectedGenres() : renderGenresList()}</div>
  );
};
