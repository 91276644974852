import DefaultButton from "../DefaultButton";
import DefaultInput from "../DefaultInput";

export default function UsernameView({
  username,
  setUsername,
  showText,
  setShowText,
  showDuplicationText,
  onClick,
  loading,
}) {
  return (
    <div
      style={{
        paddingBottom: 30,
        paddingTop: 80,
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div
        style={{
          textAlign: "left",
          minWidth: 280,
          marginLeft: -10,
        }}
      >
        <p className="apple-google-text">Select a username</p>
      </div>
      <DefaultInput
        value={username}
        setValue={setUsername}
        placeholder="Username"
        onFocus={() => setShowText(false)}
      />
      {showText && (
        <div
          style={{
            textAlign: "left",
            minWidth: 280,
            marginLeft: -10,
          }}
        >
          <p className="apple-google-text" style={{ color: "#E7FF85" }}>
            Please fill out the username first
          </p>
        </div>
      )}

      {showDuplicationText && (
        <div
          style={{
            textAlign: "left",
            minWidth: 280,
            marginLeft: -10,
          }}
        >
          <p className="apple-google-text" style={{ color: "#E7FF85" }}>
            Username is already taken. Please choose a different one
          </p>
        </div>
      )}

      <div style={{ marginTop: 180 }}>
        <DefaultButton
          title={"Let's go"}
          loading={loading} //usernameLoading}
          onClick={onClick}
        />
      </div>
    </div>
  );
}
