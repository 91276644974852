import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    background: "transparent",
    borderRadius: 10,
  },
}));

export default function DialogPopup({
  open,
  handleClose,
  title,
  children,
  showCustomFooter,
  disableBackdropClick,
  setShowText,
  disableX,
}) {
  const onClose = (e) => {
    if (disableBackdropClick) {
      return;
    }
    handleClose();
  };

  const onCloseX = (e) => {
    if (disableX) {
      setShowText(true);
      return;
    }
    handleClose();
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        disableEscapeKeyDown={!!disableBackdropClick}
        onClose={onClose}
        open={open}
        fullWidth={true}
        onAbort={handleClose}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: 55,
            background: "#0B131A",
            paddingTop: 20,
          }}
        >
          {!title ? (
            <img
              src={require("../../assets/trppn_logo.png")}
              style={{ height: 28 }}
            />
          ) : (
            <span
              style={{
                color: "white",
                fontWeight: 900,
                fontFamily: "PRRightGrotesk",
                fontStyle: "italic",
              }}
            >
              {title}
            </span>
          )}
          <div style={{ marginTop: 20 }}>
            <IconButton
              aria-label="close"
              onClick={onCloseX}
              sx={{
                position: "absolute",
                right: 8,
                top: 12,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <img
                src={require("../../assets/icon_close.png")}
                style={{ width: 18, height: 18 }}
                alt=""
              />
            </IconButton>
          </div>
        </div>
        {/* <DialogTitle
          style={{ background: "red", alignSelf: "center" }}
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
        >
         
        </DialogTitle> */}

        <div
          style={{
            width: "100%",
            background: "#0B131A",
            overflow: "hidden",
          }}
        >
          {children}
        </div>
        {!showCustomFooter ? (
          <div
            style={{
              height: 50,
              width: "100%",
              background: "#0B131A",
            }}
          ></div>
        ) : (
          <div />
        )}
      </BootstrapDialog>
    </React.Fragment>
  );
}
