import { async } from "@firebase/util";
import React, { useEffect, useState } from "react";
import {
  getDocument,
  getList,
  getUserInfoAuthenticated,
} from "../config/firebase";
const UserContext = React.createContext();
export default UserContext;

export function UserProvider({ children }) {
  const [user, setUser] = useState({});
  const [allGenres, setAllGenres] = useState([]);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [userProfileData, setUserProfileData] = useState(null);
  const [didFinishRegister, setDidFinishRegister] = useState(false);

  const handleGrabGenreList = async () => {
    const gens = await getList("Genres");
    setAllGenres(gens);
  };

  const handleGrabAndSetProfileData = async (uid) => {
    const id = uid || user?.uid;
    if (!id) return;
    const data = await getUserInfoAuthenticated(id);
    setUserProfileData(data);
  };

  useEffect(() => {
    handleGrabGenreList();
  }, []);

  useEffect(() => {
    if (!!user) {
      handleGrabAndSetProfileData();
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        allGenres,
        user,
        setUser,
        currentEvent,
        setCurrentEvent,
        userProfileData,
        handleGrabAndSetProfileData,
        didFinishRegister,
        setDidFinishRegister,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
