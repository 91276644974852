import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { CommentsListItem } from "../CommentsListItem";
import TextImageButton from "../TextImageButton";
import LoadingIndicator from "../LoadingIndicator";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function DialogView({
  open,
  handleClose,
  data,
  userData,
  onClick,
}) {
  return (
    <React.Fragment>
      <BootstrapDialog onClose={handleClose} open={open} fullWidth={true}>
        <DialogTitle
          style={{ background: "#0B131A" }}
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
        >
          <span
            style={{
              color: "white",
              fontWeight: 900,
              fontFamily: "PRRightGrotesk",
              fontStyle: "italic",
            }}
          >
            Comments
          </span>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <img
            src={require("../../assets/icon_close.png")}
            style={{ width: 18, height: 18 }}
            alt=""
          />
        </IconButton>

        <div
          style={{
            width: "100%",
            height: 600,

            background: "#0B131A",
            overflow: "hidden",
          }}
        >
          <React.Suspense fallback={<LoadingIndicator customSize={100} />}>
            {!userData ? (
              <LoadingIndicator customSize={100} />
            ) : (
              <div
                className="scrollable-list"
                style={{ maxHeight: "100%", overflowY: "auto" }}
              >
                {data.map((el, i) => (
                  <CommentsListItem
                    key={i}
                    item={el}
                    userInfo={userData[el.uid]}
                  />
                ))}
              </div>
            )}
          </React.Suspense>
        </div>
        <div
          style={{
            width: "100%",
            background: "#0B131A",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: 360,
              alignSelf: "center",
              display: "flex",
              marginTop: 25,
              marginBottom: 25,
            }}
          >
            <TextImageButton
              text="Add comments in app"
              iconName={require("../../assets/icon_arrow.png")}
              onClick={onClick}
            />
          </div>
        </div>
      </BootstrapDialog>
    </React.Fragment>
  );
}
