export const firebasAuthErrors = (code) => {
  if (code === "auth/email-already-in-use") {
    return "Email is already registered. Please go to Login";
  } else if (code === "auth/weak-password") {
    return "Password should be at least 6 characters";
  } else if (code === "auth/invalid-email") {
    return "Email is invalid";
  } else {
    return "Unknown Error";
  }
};
