import { useState, useEffect, useContext } from "react";
import AppleGoogleButton from "../AppleGoogleButton";
import {
  checkUsername,
  getOAuthProvider,
  registerUserDirectly,
  registerWithAuthToken,
  updateUser,
} from "../../config/firebase";
import { APPLE_CLIENT_ID } from "../../constants";
import { getProfilePlaceholderIndex } from "../../utils/images";
import FooterView from "../FooterView";
import { signupWithGoogle } from "../../utils/google-signup";
import UsernameView from "../UsernameView";
import EmailView from "../EmailView";
import UserContext from "../../context/UserContext";

export default function SignupView({
  setShowText,
  showText,
  closePopup,
  setDidFillOutUsername,
  setDidFinishAuth,
}) {
  const { setDidFinishRegister } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [didAuthenticate, setDidAuthenticate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);

  const [comeFromLogin, setComeFromLogin] = useState(false);
  const [showDuplicationText, setShowDuplicationText] = useState(false);

  const [username, setUsername] = useState("");
  const [usernameLoading, setUsernameLoading] = useState(false);

  const [goToEmail, setGoToEmail] = useState(false);

  useEffect(() => {
    window.AppleID.auth.init({
      clientId: APPLE_CLIENT_ID,
      scope: "email",
      redirectURI: "https://trppn-dev.site/create-event",
      state: "state",
      usePopup: true,
    });
  }, []);

  const handleAppleSignIn = async () => {
    setError(null);
    setLoading(true);
    try {
      const response = await window.AppleID.auth.signIn();

      const tokenId = response.authorization?.id_token;

      if (tokenId) {
        const provider = getOAuthProvider("apple.com");
        const credential = provider.credential({ idToken: tokenId });
        const firebaseResp = await registerWithAuthToken(
          credential,
          getProfilePlaceholderIndex()
        );

        handleCheckAndRedirect(firebaseResp);
      } else {
        setError("Error in Apple Signup - Try again later");
      }
      setLoading(false);
    } catch (error) {
      console.error(error);

      setLoading(false);
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    setLoadingGoogle(true);
    const { user, token } = await signupWithGoogle();
    try {
      const res = await registerUserDirectly(
        user,
        getProfilePlaceholderIndex()
      );
      setLoadingGoogle(false);
      handleCheckAndRedirect(res);
    } catch (error) {
      setLoadingGoogle(false);
      console.log("the error in GSU", error);
    }
  };

  const handleCheckAndRedirect = (res) => {
    if (res.success) {
      setDidAuthenticate(true);
      if (res.comeFromLogin) {
        setComeFromLogin(res.comeFromLogin);
        closePopup();
        setDidFinishRegister(true);
      } else {
        setDidFillOutUsername(true);
        setDidFinishAuth(true);
      }
    } else {
      console.log("ssomething went wrong män");
    }
  };

  const handleSetUsername = async () => {
    if (!username || username.length === 0) {
      setShowText(true);
      return;
    }
    setUsernameLoading(true);

    const usernameExists = await checkUsername(username);
    if (!usernameExists) {
      const res = await updateUser(username);
      if (res.success) {
        setDidFinishAuth(true);
        closePopup();
        setDidFinishRegister(true);
      } else {
        console.log("some saving error: try again");
      }
    } else {
      setUsernameLoading(false);
      setShowDuplicationText(true);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 50,
      }}
    >
      {!didAuthenticate ? (
        <>
          {!goToEmail ? (
            <>
              <AppleGoogleButton
                type={"apple"}
                title="Continue with Apple"
                setError={setError}
                loading={loading}
                onClick={handleAppleSignIn}
              />
              <AppleGoogleButton
                type={"google"}
                title="Continue with Google"
                onClick={handleGoogleSignIn}
                setError={setError}
                loading={loadingGoogle}
              />
              <AppleGoogleButton
                onClick={() => setGoToEmail(true)}
                type={"email"}
                title="Continue with Email"
              />
              {!!error && (
                <p className="apple-google-text" style={{ color: "red" }}>
                  {error}
                </p>
              )}
              <div style={{ marginTop: 30 }}>
                <FooterView />
              </div>
            </>
          ) : (
            <>
              <EmailView
                setDidAuthenticate={setDidAuthenticate}
                closePopup={closePopup}
                setDidFillOutUsername={setDidFillOutUsername}
              />
            </>
          )}
        </>
      ) : (
        <UsernameView
          username={username}
          setUsername={setUsername}
          showText={showText}
          setShowText={setShowText}
          showDuplicationText={showDuplicationText}
          onClick={handleSetUsername}
          loading={usernameLoading}
        />
      )}
    </div>
  );
}
