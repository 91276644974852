import React, { useEffect, useState } from "react";
import { getAddressAsync } from "../../config/firebase";

function AddressInput({ setLocation, onFocus, init }) {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (!!init) {
      setInputValue(init);
    }
  }, [init]);

  // Function to update the input value and fetch suggestions
  const handleChange = async (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value.length > 2) {
      const newSuggestions = await getAddressAsync(value);
      setSuggestions(newSuggestions || []);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion?.description || "");
    setLocation(suggestion);
    setSuggestions([]);
  };

  return (
    <div>
      <input
        style={{
          background: "#1b212a",
          outline: "none",
          border: "none",
          color: "white",
          width: "100%",
          margin: 0,
          padding: 15,
          borderRadius: 10,
        }}
        type="text"
        value={inputValue}
        onChange={handleChange}
        placeholder="Type an address or venue name"
        onFocus={onFocus}
      />
      {suggestions.length > 0 && (
        <ul
          style={{
            listStyleType: "none",
            padding: 5,
            margin: 0,
            position: "absolute", // Absolute positioning for the dropdown
            width: "100%", // Match the width of the input field
            backgroundColor: "black",
            maxWidth: 380,
            boxShadow: "0px 2px 4px rgba(0,0,0,0.2)", // Optional: adds a shadow to the dropdown
            zIndex: 1000, // Ensure the dropdown overlays other content
          }}
        >
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              style={{ cursor: "pointer", color: "white" }}
              className="apple-google-text"
            >
              {suggestion?.description || ""}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default AddressInput;
