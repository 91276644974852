import { Switch } from "@mui/material";

export default function SwitchButton({ text, value, setValue }) {
  return (
    <button
      style={{
        display: "flex",
        backgroundColor: "#1b212a",
        outline: "none",
        border: "none",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 3,
        borderRadius: 10,
      }}
    >
      <span
        className="apple-google-text"
        style={{ fontSize: 15, color: "#757575" }}
      >
        {text}
      </span>
      <Switch value={value} onChange={(e) => setValue(e.target.checked)} />
    </button>
  );
}
