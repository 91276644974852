import React from "react";
import gif from "../../assets/trppn_loading.gif";

const LoadingIndicator = ({ customSize }) => {
  const size = customSize || 300;
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <img src={gif} alt="" style={{ width: size, height: size }} />
    </div>
  );
};

export default LoadingIndicator;
