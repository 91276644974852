export const APPSTORE_URLS = {
  ios: "https://apps.apple.com/app/1580642989",
  android: "https://play.google.com/store/apps/details?id=com.roc8.trppn",
};

export const REDIRECT_DOMAINS = {
  development: "https://trppn-development.web.app",
  staging: "https://trppn-staging.web.app",
  production: "https://trppn.link",
};

export const APP_SCHEMES = {
  development: "trppn-dev://",
  staging: "trppn-staging://",
  production: "trppn://",
};

export const LANDING_PAGE = "https://trppn.io";

export const TERMS_AND_CONDITIONS = "https://www.trppn.io/terms";

export const APPLE_CLIENT_ID = "web.roc8.trppn.dev";
