import ReactLoadingComponent from "../../ReactLoadingComponent";

import "./DefaultButton.css";

export default function DefaultButton({ title, onClick, loading, ...props }) {
  return (
    <button
      onClick={onClick}
      className="default-button"
      style={props.style}
      disabled={loading}
    >
      {!loading ? (
        <span>{title}</span>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoadingComponent />
        </div>
      )}
    </button>
  );
}
