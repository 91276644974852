const firebaseConfig = () => {
  if (process.env.REACT_APP_APP_ENV === "production") {
    return require("./firebase-production.json");
  } else if (process.env.REACT_APP_APP_ENV === "staging") {
    return require("./firebase-staging.json");
  } else {
    return require("./firebase-dev.json");
  }
};

export default firebaseConfig;
