import React from "react";
import "./AttendButton.css";
// import rightArrow from "./right-arrow.svg";

const AttendButton = ({ text, onClick, iconName, ...props }) => {
  return (
    <button className={"button-style"} onClick={onClick} {...props}>
      <img
        src={require("../../assets/ph_attends.png")}
        style={{ height: 40 }}
        alt=""
      />
      <div className="text-container">
        <span className="button-text">Attend</span>
        <img
          src={iconName}
          alt="Arrow"
          className="button-image"
          style={{ width: 28, height: 28, marginLeft: 8 }}
        />
      </div>
    </button>
  );
};

export default AttendButton;
