import axios from "axios";
import FormData from "form-data";

export const uploadImageToCloudflare = async (imageFile, signedUrl) => {
  try {
    const formData = new FormData();
    const fileName = "banner." + imageFile.type.split("/")[1]; // Assuming type is like 'image/jpeg'
    console.log("The file name is", fileName);

    formData.append("file", imageFile, fileName);

    const response = await axios.post(signedUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("Upload successful. Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error uploading image to Cloudflare:", error);
    throw error;
  }
  //   try {
  //     const { uri, name, extension } = imageFile;
  //     const formData = new FormData();
  //     const type = "image/" + extension;
  //     console.log("the name is ", name, extension, type);

  //     formData.append("file", {
  //       uri: uri,
  //       name: name || "banner." + extension,
  //       type: type,
  //     });
  //     console.log("the uri", uri);
  //     const response = await axios.post(signedUrl, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error uploading image to Cloudflare:", error);
  //     throw error;
  //   }
};

export const createImageUrl = (signedUrl) => {
  const id = extractImageUrl(signedUrl);
  return `https://imagedelivery.net/${id}/public`;
};

export const extractImageUrl = (signedUrl) => {
  const packets = signedUrl.split(".net/");
  return packets[1];
};
