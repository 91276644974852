import "./ImageButton.css";
export default function ImageButton({ src, onClick }) {
  return (
    <button className="blank-button" onClick={onClick}>
      <img
        src={src || require("../../assets/icon_menu.png")}
        style={{ width: 44 }}
      />
    </button>
  );
}
