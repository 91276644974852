import React, { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    window.location = "https://trppn.io";
  }, []);

  return <h1></h1>;
};

export default Home;
