import { printMessage } from "../config/firebase";
import {
  APPSTORE_URLS,
  APP_SCHEMES,
  LANDING_PAGE,
  REDIRECT_DOMAINS,
} from "../constants";

/*
Redirecting Logic: (Appstore/app redirects)

  Buttons:
    - Attend -> to app/event
    - Reels -> to app/event
    - Open in app -> to app/event
    - Add comment in app -> to app/event

    - Discover more events -> to app
    - Host an event -> to app

  Mobile:
    Normal Browser
      iOS/Android - dynamic link -> back to Share App -> redirect to either appstore or to app
    In-App-Browser
      iOS/Android - since dynamic links don't work for these browsers we cannot redirect
                    to the Share App, since the redirect to app doesnt work.
                    So we have to try to connect directly to the app-scheme (which works), 
                    but we get an error if the app is not installed.
                    Solution:
                    So we could trigger a delayed popup with direct call to the app store, 
                    two seconds after the direct redirect to the app
  Desktop:
    Browser - Show popup to download Apps (e.g. QR for iOS / Android)
  
    Open in app: check if mobile -> YES: -> check if instagram browser -> YES: try redirect to app-url + show delayed popup
                                                                       ->  NO: redirect to Share App with note to directly go to appstore
                                 ->  NO: -> show QR code popup 
  
  
                                 
*/
export const appRedirect = (shareId, openAppOnly) => {
  const userAgent = navigator.userAgent;
  const suffix = !!openAppOnly ? "ext" : "";
  if (isInstagramInAppBrowser(userAgent)) {
    //CASE: Instagram web browser

    redirectToURLScheme(shareId, suffix);
  } else {
    //CASE: normal web browser

    //TODO: if device == "other" -> open popup with QR codes for all action buttons

    redirectToAppOrWeb(shareId, suffix);
  }

  //check first if IG Browser or not
  // -> yes: redirect to app://event
  // -> no: android/ios -> redirect to trppn.link/shareId
  //then check which specific device

  //
};

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const getUserDevice = (ua) => {
  if (/android/i.test(ua)) {
    return "Android";
  } else if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
    return "iOS";
  } else {
    return "Other";
  }
};

export const getDeviceType = (ua) => {
  if (/android/i.test(ua)) {
    return "android";
  } else if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
    return "ios";
  } else {
    return "web";
  }
};

export const isWeb = (ua) => {
  if (/android/i.test(ua)) {
    return false;
  } else if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
    return false;
  } else {
    return true;
  }
};

const redirectToAppOrWeb = (shareId, suff) => {
  const domain = REDIRECT_DOMAINS[process.env.REACT_APP_APP_ENV];
  const url = domain + `/${shareId}&webview${suff}`;
  openInNewTab(url);
};

const redirectToURLScheme = (shareId, suff) => {
  const scheme = APP_SCHEMES[process.env.REACT_APP_APP_ENV];
  const url = scheme + `${shareId}${suff}`;
  window.location = url;

  const userAgent = navigator.userAgent;
  const device = getUserDevice(userAgent);
  printMessage(scheme);

  const appstoreUrl =
    device === "iOS" ? APPSTORE_URLS.ios : APPSTORE_URLS.android;
  const time = getUserDevice(userAgent) === "Android" ? 2000 : 1000;
  setTimeout(() => {
    if (document.visibilityState === "visible") {
      window.location = appstoreUrl;
    }
  }, time);
};

export const extractIdFromRoute = (path) => {
  const segments = path.split("&");
  let shareId = segments.length > 1 ? segments[1] : null;
  if (shareId && shareId.includes("amp;")) {
    shareId = shareId.replace("amp;", "");
  }
  return { eventId: segments[0], shareId: shareId };
};

export const isInstagramInAppBrowser = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes("instagram");
};

export const shareLink = async (shareId, showPopup) => {
  const domain = REDIRECT_DOMAINS[process.env.REACT_APP_APP_ENV];
  const url = domain + `/${shareId}`;
  if (navigator.share) {
    await navigator.share({
      title: "Share Event",
      url: url,
    });
  } else {
    console.log("Sharedialog not available");
    showPopup();
  }
};

export const getShareLink = (shareId) => {
  const domain = REDIRECT_DOMAINS[process.env.REACT_APP_APP_ENV];
  if (!shareId) return LANDING_PAGE;
  return domain + `/${shareId}`;
};
