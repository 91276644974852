export default function TextView({ value, setValue }) {
  return (
    <textarea
      className="apple-google-text"
      style={{
        border: "none",
        outline: "none",
        background: "#1B212A",
        borderRadius: 10,
        margin: 0,
        resize: "none",
        padding: 15,
        fontSize: 15,
        width: "93%",
      }}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      rows="4" // Sets the initial number of visible text lines
      cols="50" // Sets the width of the textarea (in average character width)
      placeholder="Enter multiple lines of text here"
    />
  );
}
