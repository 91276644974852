import React from "react";
import { APPSTORE_URLS } from "../../constants";
// import { Link } from "react-router-dom";
import "./Navbar.css"; // The path to your CSS file

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        {/* <Link to="/">Your Logo Here</Link> */}
      </div>
      <div className="navbar-links">
        {/* <Link to="/link1">Link1</Link>
        <Link to="/link2">Link2</Link>
        Add more links as needed */}
      </div>
      <div>
        <a href={APPSTORE_URLS.ios} target="_blank" rel="noopener noreferrer">
          <img
            src={require("../../assets/button_appstore.png")}
            style={{ height: 33 }}
            alt=""
          />
        </a>
        <a
          href={APPSTORE_URLS.android}
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginLeft: 10 }}
        >
          <img
            src={require("../../assets/button_playstore.png")}
            style={{ height: 33 }}
            alt=""
          />
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
