import { getRandomNumberUntilN } from "./utils";

const eventPlaceholders = [
  require("../assets/banner1.png"),
  require("../assets/banner2.png"),
  require("../assets/banner3.png"),
  require("../assets/banner4.png"),
  require("../assets/banner5.png"),
  require("../assets/banner6.png"),
];

const profilePlaceholders = [
  require("../assets/profile_ph_1.png"),
  require("../assets/profile_ph_2.png"),
  require("../assets/profile_ph_3.png"),
  require("../assets/profile_ph_4.png"),
  require("../assets/profile_ph_5.png"),
  require("../assets/profile_ph_6.png"),
];

export const getPlaceholderImage = (ind) => {
  if (!ind) return eventPlaceholders[0];
  if (ind > eventPlaceholders.length)
    return eventPlaceholders[eventPlaceholders.length - 1];
  return eventPlaceholders[ind];
};

export const getPlaceholderImageUser = (ind) => {
  if (!ind) return profilePlaceholders[0];
  if (ind > profilePlaceholders.length)
    return profilePlaceholders[profilePlaceholders.length - 1];
  return profilePlaceholders[ind];
};

export const getProfilePlaceholderIndex = () => {
  return getRandomNumberUntilN(profilePlaceholders.length);
};

export const getEventPlaceholderindex = () => {
  return getRandomNumberUntilN(eventPlaceholders.length);
};
