import React from "react";
import "./TextImageButton.css";
// import rightArrow from "./right-arrow.svg";

const TextImageButton = ({ text, onClick, className, iconName, ...props }) => {
  const style = className || "button-style";
  return (
    <button className={style} onClick={onClick} {...props}>
      <span className="button-text">{text}</span>
      <img
        src={iconName}
        alt=""
        className="button-image"
        style={{ width: 28, height: 28 }}
      />
    </button>
  );
};

export default TextImageButton;
