import "./SignupProfileView.css";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../context/UserContext";
import { getPlaceholderImageUser } from "../../utils/images";
import ImageButton from "../ImageButton";
import SignupButton from "../SignupButton";
import DialogPopup from "../DialogPopup";
import SignupView from "../SignupView";

export default function SignupProfileView({ user, open }) {
  const { userProfileData, handleGrabAndSetProfileData } =
    useContext(UserContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const [didFilloutUsername, setDidFillOutUsername] = useState(true);
  const [showText, setShowText] = useState(false);
  const [didFinishAuth, setDidFinishAuth] = useState(false);

  useEffect(() => {
    if (open) {
      setOpenSignup(true);
    }
  }, [open]);

  useEffect(() => {
    if (didFinishAuth) {
      setDidFinishAuth(false);
      handleGrabAndSetProfileData();
    }
  }, [didFinishAuth]);

  console.log("the user is ", user);

  return (
    <>
      {!user ? (
        <div
          className="login-area"
          style={{ position: "absolute", right: 15, top: 70 }}
        >
          <SignupButton
            style={{ background: "#E7FF85", marginBottom: 10 }}
            onClick={() => setOpenSignup(true)}
          />
          <SignupButton
            textColor="#E7FF85"
            style={{
              background: "transparent",
              borderWidth: 1,
              borderColor: "#E7FF85",
            }}
            onClick={() => setOpenSignup(true)}
            title="Login"
          />
        </div>
      ) : (
        <div
          className="login-area profile-area"
          style={{
            position: "absolute",
            right: 15,
            top: 70,
          }}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <img
            alt=""
            style={{ width: 40, height: 40, borderRadius: 20 }}
            src={
              !userProfileData
                ? null
                : userProfileData?.thumbnailUrl ||
                  getPlaceholderImageUser(userProfileData?.phIndex)
            }
          />
          <span
            className="apple-google-text"
            style={{ fontSize: 15, textAlign: "center", margin: 0 }}
          >
            {userProfileData?.username || ""}
          </span>
          {isDropdownOpen && (
            <div className="dropdown">
              <div className="flex-container">
                <button
                  className="default-button-dark"
                  style={{ marginTop: 10, marginBottom: 10, width: "90%" }}
                >
                  <span className="apple-google-text">
                    See all events in app
                  </span>
                </button>
                <button
                  className="default-button-dark"
                  style={{ marginBottom: 10, width: "90%" }}
                >
                  <span className="apple-google-text">Go to last event</span>
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      <div
        className="collapse-button"
        style={{ position: "absolute", right: 15, top: 90 }}
      >
        <ImageButton onClick={() => setIsDropdownOpen(!isDropdownOpen)} />
        {isDropdownOpen && (
          <div className="dropdown">
            <div className="flex-container">
              <button
                className="default-button-dark"
                style={{ marginTop: 10, marginBottom: 10, width: "90%" }}
              >
                <span className="apple-google-text">See all events in app</span>
              </button>
              <button
                className="default-button-dark"
                style={{ marginBottom: 10, width: "90%" }}
              >
                <span className="apple-google-text">Go to last event</span>
              </button>
            </div>
          </div>
        )}
      </div>
      {openProfile && (
        <div>
          <DialogPopup
            open={openProfile}
            handleClose={() => setOpenProfile(false)}
          >
            <div className="flex-container">
              <p className="apple-google-text">
                A LOT OF ROOM FOR PROFILE STUFF!
              </p>
              <p className="apple-google-text">- EDIT PROFILE IMAGE!</p>
              <p className="apple-google-text">- SELECT CREATOR TYPE!</p>
              <p className="apple-google-text">- ACCESS TO LAST EVENT!</p>
              <p className="apple-google-text">- TRIGGER TO DOWNLOAD APP!</p>
            </div>
          </DialogPopup>
        </div>
      )}

      {openSignup && (
        <div>
          <DialogPopup
            open={openSignup}
            handleClose={() => setOpenSignup(false)}
            showCustomFooter={true}
            disableBackdropClick={didFilloutUsername}
            setShowText={setShowText}
            disableX={false}
          >
            <SignupView
              setShowText={setShowText}
              showText={showText}
              closePopup={() => setOpenSignup(false)}
              setDidFillOutUsername={setDidFillOutUsername}
              setDidFinishAuth={setDidFinishAuth}
            />
          </DialogPopup>
        </div>
      )}
    </>
  );
}
