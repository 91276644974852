import "./DefaultInput.css";

export default function DefaultInput({
  placeholder,
  value,
  setValue,
  onFocus,
  passwordMode,
  ...props
}) {
  const handleSetVal = (e) => {
    setValue(e.target.value);
  };
  return (
    <div>
      <input
        className="ip-fff"
        type={passwordMode ? "password" : "text"}
        placeholder={placeholder || ""}
        value={value}
        onChange={handleSetVal}
        style={{
          background: "#1B212A",
          color: "white",
          border: "none",
          ...props.style,
        }}
        onFocus={onFocus}
      />
    </div>
  );
}
