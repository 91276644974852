import { ColorRing } from "react-loader-spinner";
import "./AppleGoogleButton.css";
const iconApple = require("../../assets/icon_apple.png");
const iconGoogle = require("../../assets/icon_google.png");
const arrow = require("../../assets/icon_right_arrow.png");

export default function AppleGoogleButton({ type, title, onClick, loading }) {
  return (
    <button onClick={onClick} className="apple-google-button">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 0.85,
          alignItems: "center",
          paddingLeft: 5,
        }}
      >
        {type !== "email" && (
          <img
            src={type === "apple" ? iconApple : iconGoogle}
            style={{ width: 24, heigt: 24 }}
          />
        )}
        {loading ? (
          <ColorRing
            height="25"
            width="25"
            radius="9"
            colors={[
              "#001E8A",
              "#001E8A",
              "#001E8A",
              "#001E8A",
              "#001E8A",
              "#001E8A",
            ]}
          />
        ) : (
          <span className="apple-google-text">{title}</span>
        )}
      </div>
      <img src={arrow} style={{ width: 24 }} />
    </button>
  );
}
