import moment from "moment";

export const getDateStringRep = (d) => {
  const date = moment(d);
  const dateString = date.format("ddd Do MMM");
  return dateString;
};

export const getHourStringRep = (d) => {
  const date = moment(d);
  const dateString = date.format("hh:mm A");
  return dateString;
};

export const getTimeAgo = (timestamp, hideAgo) => {
  if (!timestamp || !timestamp._seconds) return "";
  const now = new Date().getTime();

  const diff = now - timestamp._seconds * 1000;
  if (diff < 60000) {
    // less than 1 minute
    return "just now";
  } else if (diff < 3600000) {
    // less than 1 hour
    const minutes = Math.floor(diff / 60000);
    return `${minutes} MIN ${hideAgo ? "" : "AGO"}`;
  } else if (diff < 86400000) {
    // less than 1 day
    const hours = Math.floor(diff / 3600000);
    return `${hours}H ${hideAgo ? "" : "AGO"}`;
  } else if (diff < 604800000) {
    // less than 7 days (7 days * 24 hours * 60 minutes * 60 seconds * 1000 ms)
    // more than 1 day but less than a week
    const days = Math.floor(diff / 86400000);
    const dayString = days === 1 ? "DAY" : "DAYS";
    return `${days} ${dayString} ${hideAgo ? "" : "AGO"}`;
  } else {
    // more than a week
    const weeks = Math.floor(diff / 604800000);
    const weekString = weeks === 1 ? "WEEK" : "WEEKS";
    return `${weeks} ${weekString} ${hideAgo ? "" : "AGO"}`;
  }
};
