import "./DateComponent.css";
import ReactDatePicker from "react-datepicker";

export default function DateComponent({
  text,
  date,
  setDate,
  top = 8,
  minDate,
  maxDate,
}) {
  const handleChange = (d) => {
    console.log("the date is ", d);
    setDate(d);
  };
  return (
    <div
      style={{
        marginTop: top,
        background: "#1b212a",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 5,
        borderRadius: 10,
      }}
    >
      <span
        className="apple-google-text"
        style={{
          color: "#757575",
          fontSize: 15,
        }}
      >
        {text}
      </span>
      <ReactDatePicker
        className="custom-date-class"
        selected={date}
        onChange={handleChange}
        showTimeSelect
        dateFormat="dd.MM.YYYY h:mm aa"
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
}
