import { TERMS_AND_CONDITIONS } from "../../constants";

export default function FooterView() {
  return (
    <div
      style={{
        width: "100%",
        background: "#0B131A",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingBottom: 30,
      }}
    >
      <p
        className="apple-google-text"
        style={{ fontSize: 13, color: "white", opacity: 0.7 }}
      >
        By continuing you accept our
      </p>
      <a
        style={{ textDecoration: "none" }}
        href={TERMS_AND_CONDITIONS}
        target="_blank"
      >
        <span className="apple-google-text">TERMS ANS CONDITIONS</span>
      </a>
    </div>
  );
}
